import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const HeroContainer = styled.div`
  position: relative;
  display: block;
  background-color: ${LOCAL_COLORS.surfaceLight};
  padding-bottom: ${SPACER.x3large};

  ${MEDIA_QUERIES.mdUp} {
    padding: 0;
  }

  h1,
  p {
    color: ${LOCAL_COLORS.surfaceDarkBold};
  }
`;

export const LeftContent = styled.div`
  padding: 0 ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    padding: ${SPACER.x3large} ${SPACER.xlarge};
  }

  ${MEDIA_QUERIES.xlUp} {
    padding: 80px 72px;
  }
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 96%;
  letter-spacing: -1.6px;
  margin-bottom: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    font-family: var(--header-font);
    font-size: 32px;
  }

  ${MEDIA_QUERIES.lgUp} {
    font-size: 40px;
    letter-spacing: -2px;
  }

  ${MEDIA_QUERIES.xlUp} {
    font-size: 60px;
  }

  ${MEDIA_QUERIES.xxlUp} {
    font-size: 72px;
    letter-spacing: -3.6px;
  }
`;

export const Subtitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 132%;
  margin-bottom: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    color: #ffffff;
    font-weight: 600;
  }

  ${MEDIA_QUERIES.xlUp} {
    font-size: 24px;
    line-height: 140%;
  }
`;

export const ValueProps = styled.ul`
  margin-bottom: ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: ${SPACER.large};
  }

  ${MEDIA_QUERIES.lgUp} {
    margin-bottom: ${SPACER.x2large};
  }

  ${MEDIA_QUERIES.xlUp} {
    margin-bottom: ${SPACER.medium};
  }

  @media (min-width: 1260px) {
    margin-bottom: ${SPACER.x2large};
  }
`;

export const ValueProp = styled.li`
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  background-color: ${LOCAL_COLORS.surfaceBrandLight};
  width: fit-content;
  padding: 1px 5px;

  & + & {
    margin-top: 8px;
  }

  ${MEDIA_QUERIES.mdUp} {
    font-size: 12px;
    padding: ${SPACER.xsmall} ${SPACER.small};
  }

  ${MEDIA_QUERIES.lgUp} {
    font-size: 16px;
    padding: 9px ${SPACER.medium};
  }
`;

export const ctaStyles = css`
  max-width: unset;

  button {
    color: ${LOCAL_COLORS.surfaceWhite};
    font-size: 16px;
    font-weight: 700;
    line-height: 132%;
    letter-spacing: -0.32px;

    ${MEDIA_QUERIES.mdUp} {
      font-size: 14px;
      width: fit-content;
    }

    ${MEDIA_QUERIES.lgUp} {
      font-size: 16px;
    }
  }

  ${MEDIA_QUERIES.mdUp} {
    max-width: fit-content;
  }
`;
