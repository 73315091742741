import React, { ReactNode } from 'react';

import { CtaButton } from 'components/global/CtaButton';

import {
  HeroContainer,
  LeftContent,
  Subtitle,
  Title,
  ValueProp,
  ValueProps,
  ctaStyles,
} from './SplitHeroPop.style';

export interface SplitHeroPopProps {
  images: ReactNode;
  title: string;
  subtitle?: string;
  valueProps?: string[];
  ctaText?: string;
}

const moduleName = 'splitHeroPop';

export const SplitHeroPop = ({
  images,
  title,
  subtitle,
  valueProps = [],
  ctaText,
}: SplitHeroPopProps) => (
  <HeroContainer data-module={moduleName}>
    <div>{images}</div>
    <LeftContent>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <ValueProps>
        {valueProps.map((prop, index) => (
          <ValueProp key={index}>{prop}</ValueProp>
        ))}
      </ValueProps>
      {ctaText && (
        <CtaButton css={ctaStyles} buttonText={ctaText} color="dark-blue" />
      )}
    </LeftContent>
  </HeroContainer>
);
