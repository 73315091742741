import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

import type { SplitHeroPopProps } from '../SplitHeroPop';

export const ACNE_SPLIT_HERO_CONTENT: SplitHeroPopProps = {
  images: (
    <React.Fragment>
      <StaticImage
        src="../images/heroMobile@2x.jpg"
        alt="Brunette woman smiling, wearing a white top, holding up a white Custom Formula Curology bottle in her left hand."
        css={[mobileOnly()]}
        loading="eager"
        objectFit="fill"
        objectPosition="center"
      />
      <StaticImage
        src="../images/heroDesktop@2x.jpg"
        alt="Brunette woman smiling, wearing a white top, holding up a white Custom Formula Curology bottle in her left hand."
        css={[desktopOnly()]}
        loading="eager"
        objectFit="fill"
        objectPosition="bottom"
      />
    </React.Fragment>
  ),
  title: 'Personalized skincare to help clear your skin',
  subtitle: 'Start your journey to clearer skin today.',
  valueProps: [
    'Powered by 100+ Licensed Dermatology Providers',
    '3 active ingredients in one formula',
    '93% report effective*',
  ],
  ctaText: 'Get my formula',
};
